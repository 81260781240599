import React, { useState, useEffect } from 'react';
import { getRSI } from '../../../services/IndexesService'; // Altere para o serviço de RSI, se necessário
import Menu from '../../../components/Menu/Menu';
import Footer from '../../../components/Footer';
import './mgs.css';

function IndexesRSI() {
    const [rsiData, setRsiData] = useState([]); // Estado para os dados de RSI
    const [filteredData, setFilteredData] = useState([]); // Estado para dados filtrados
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(""); // Estado para o valor da busca

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getRSI(); // Altere para a função que busca os dados de RSI
                setRsiData(data);
                setFilteredData(data); // Inicializa com todos os dados
            } catch (error) {
                console.error('Erro ao buscar os dados de RSI:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        // Filtra os dados sempre que o termo de busca mudar
        const results = rsiData.filter(item =>
            item.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(results);
    }, [searchTerm, rsiData]);

    // Função para determinar a classe com base no valor do RSI
    const getRsiClass = (rsi) => {
        if (rsi > 70) {
            return 'overbought'; // Vermelho: RSI sobrecomprado
        } else if (rsi < 30) {
            return 'oversold'; // Azul: RSI sobrevendido
        } else {
            return 'neutral'; // Amarelo: RSI neutro
        }
    };

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Analysis - RSI</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="input-group">
                            <span class="input-group-text" id="basic-addon1">
                                <svg class="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            {/* Campo de busca */}
                            <input
                                type="text"
                                className="form-control"
                                placeholder="search symbol. . ."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o termo de busca
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-header">
                            <div className="row">
                                <div className="col">
                                    <h2 className="fs-5 fw-bold mb-0">RSI</h2>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            {loading ? (
                                <p>Carregando dados...</p>
                            ) : (
                                <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="border-bottom" scope="col">Symbol</th>
                                            <th className="border-bottom col-2" scope="col">Base</th>
                                            <th className="border-bottom col-2" scope="col">1h</th>
                                            <th className="border-bottom col-2" scope="col">4h</th>
                                            <th className="border-bottom col-2" scope="col">1d</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {/* Exibindo a imagem junto ao símbolo */}
                                                    <img
                                                        className="me-2"
                                                        width={16}
                                                        src={`/img/icons/color/usdt.svg`}
                                                    />
                                                    {item.symbol}
                                                </td>
                                                <td>
                                                    <img
                                                        className="me-2"
                                                        width={16}
                                                        src={`/img/icons/color/${item.base.toLowerCase()}.svg`}
                                                    />
                                                    {item.base}
                                                </td>
                                                <td className={getRsiClass(item.intervals["1h"]?.rsi)}>
                                                    {item.intervals["1h"]?.rsi?.toFixed(2) || "N/A"}
                                                </td>
                                                <td className={getRsiClass(item.intervals["4h"]?.rsi)}>
                                                    {item.intervals["4h"]?.rsi?.toFixed(2) || "N/A"}
                                                </td>
                                                <td className={getRsiClass(item.intervals["1d"]?.rsi)}>
                                                    {item.intervals["1d"]?.rsi?.toFixed(2) || "N/A"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    );
}

export default IndexesRSI;
