import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { registration } from '../../services/Registration';

function Registration() {

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        limitId: 4,
        isActive: 1
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true); // Mostra o carregamento
        registration(formData)
            .then(result => {
                setSuccessMessage('Registration successful! Please login now.');
                setError('');
                navigate('/');
            })
            .catch(err => {
                const errorMessage = err.response && err.response.data
                    ? err.response.data.error || err.response.data.message || "An unknown error occurred."
                    : err.message || "An unknown error occurred.";
                setError(errorMessage); // Define apenas o texto da mensagem
                setSuccessMessage('');  // Limpa a mensagem de sucesso
            }).finally(() => setIsLoading(false)); // Oculta o carregamento
    };

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                <div className="container">
                    <p className="text-center">
                        <Link to="/" className="d-flex align-items-center justify-content-center"><svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                            Back to homepage  </Link>
                    </p>
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                            <div className="text-center">
                                <img src="/img/favicon/mstile-150x150.png" alt="Tcoinbot" width={64} />
                            </div>
                            <div className="text-center text-md-center mb-4 mt-md-0">
                                <h1 className="mb-0 h3">Create a new account</h1>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="form-group mb-4">
                                    <label htmlFor="fullName">Full Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" >
                                                <path d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                            </svg>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Full Name" id="name" value={formData.name} required onChange={onChangeInput} />
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="email">Your Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                        </span>
                                        <input type="email" className="form-control" placeholder="example@company.com" id="email" value={formData.email} required onChange={onChangeInput} />
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="phone">Telephone</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
                                                <path fillRule="evenodd" d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        <input type="tel" className="form-control" placeholder="+551123456789" id="phone" value={formData.phone} required onChange={onChangeInput} />
                                    </div>
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-gray-800" disabled={isLoading}>
                                        {isLoading ? 'Loading...' : 'Create'}
                                    </button>
                                </div>
                                {/* Exibe a mensagem de erro, se existir */}
                                {error && <div className="alert alert-danger mt-2">{error}</div>}
                                {/* Exibe a mensagem de sucesso, se existir */}
                                {successMessage && <div className="alert alert-success mt-2">{successMessage}</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Registration;

