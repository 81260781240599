import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export async function registration(newUser) {
    console.log(newUser)
    const registration = `${API_URL}/registration/`;
    const response = await axios.post(registration, newUser);
    return response.data;
}

