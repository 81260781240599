import React from 'react';

function WalletRow(props) {
    if (!parseFloat(props.available) && !parseFloat(props.onOrder)) return <></>;

    return (
        <tr>
            <td className="text-gray-900">
                <img
                    alt={props.symbol}
                    className="me-2"
                    width={16}
                    src={
                        props.symbol.startsWith("LD")
                            ? `/img/icons/color/${props.symbol.slice(2).toLowerCase()}.svg`
                            : `/img/icons/color/${props.symbol.toLowerCase()}.svg`
                    }
                />
                {props.symbol}
            </td>
            <td className="text-gray-900">
                {`${parseFloat(props.available).toFixed(8)}`.substring(0, 10)}
            </td>
            <td className="text-gray-900">
                {`${parseFloat(props.onOrder).toFixed(8)}`.substring(0, 10)}
            </td>
        </tr>
    )
}

export default WalletRow;