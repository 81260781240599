import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    // Processar os dados iniciais
    const { data } = props;

    const topData = this.getTopData(data);

    this.state = {
      series: topData.series,
      options: {
        chart: {
          height: 400,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              offsetX: -8,
              fontSize: "16px",
              formatter: function (seriesName, opts) {
                return (
                  seriesName +
                  ":  " +
                  opts.w.globals.series[opts.seriesIndex].toFixed(2)
                );
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5", "#f45b69"],
        labels: topData.labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  }

  // Função para obter os Top 5 dados
  getTopData(data) {
    const sortedData = data
      .slice()
      .sort((a, b) => parseFloat(b.available) - parseFloat(a.available)) // Ordenar por available (maior para menor)
      .slice(0, 10); // Pegar os 5 primeiros

    const series = sortedData.map((item) => parseFloat(item.available) || 0);
    const labels = sortedData.map((item) => item.symbol);

    return { series, labels };
  }

  componentDidUpdate(prevProps) {
    // Atualizar dados quando as props mudarem
    if (prevProps.data !== this.props.data) {
      const topData = this.getTopData(this.props.data);

      this.setState({
        series: topData.series,
        options: {
          ...this.state.options,
          labels: topData.labels,
        },
      });
    }
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="radialBar"
            height={450}
          />
        </div>
      </div>
    );
  }
}

export default ApexChart;
