import React, { useState, useEffect } from "react";
import { getSymbols } from "../../../services/SymbolsService";
import Menu from '../../../components/Menu/Menu';
import Footer from '../../../components/Footer';
import { atr } from 'technicalindicators';

const calculateSupertrend = (highs, lows, closes, period = 10, multiplier = 3) => {
    if (!highs || !lows || !closes || highs.length !== lows.length || highs.length !== closes.length) {
        return []; // Retorna um array vazio se os dados estiverem faltando
    }

    const atrValues = atr({ high: highs, low: lows, close: closes, period });
    const supertrend = [];
    let previousSupertrend = null;

    for (let i = period - 1; i < highs.length; i++) {
        const upperBasicBand = (highs[i] + lows[i]) / 2 + multiplier * atrValues[i];
        const lowerBasicBand = (highs[i] + lows[i]) / 2 - multiplier * atrValues[i];

        const finalUpperBand = i > period - 1 && previousSupertrend
            ? Math.min(upperBasicBand, previousSupertrend)
            : upperBasicBand;

        const finalLowerBand = i > period - 1 && previousSupertrend
            ? Math.max(lowerBasicBand, previousSupertrend)
            : lowerBasicBand;

        const trendDirection = closes[i] > finalUpperBand ? 1 : closes[i] < finalLowerBand ? -1 : 0;

        supertrend.push({
            upperBand: finalUpperBand || 0, // Garantir que não seja NaN
            lowerBand: finalLowerBand || 0, // Garantir que não seja NaN
            trendDirection,
        });

        previousSupertrend = trendDirection === 1 ? finalLowerBand : finalUpperBand;
    }

    return supertrend;
};

function Indexes() {
    const [klineData, setKlineData] = useState([]); // Dados de velas
    const [symbols, setSymbols] = useState([]); // Lista de símbolos
    const [filteredData, setFilteredData] = useState([]); // Dados filtrados
    const [symbol, setSymbol] = useState("BTCUSDT"); // Símbolo selecionado
    const [interval, setInterval] = useState("1h"); // Intervalo selecionado

    // Função para obter as URLs da API com base no símbolo e intervalo
    const getBinanceAPIUrls = (symbol, interval) => {
        return `https://api.binance.com/api/v1/klines?symbol=${symbol}&interval=${interval}&limit=100`;
    };

    // UseEffect para carregar símbolos da API
    useEffect(() => {
        const loadSymbols = async () => {
            try {
                const data = await getSymbols(); // Chama o serviço para pegar os símbolos
                const symbolList = data.rows.map(item => ({
                    symbol: item.symbol,
                    base: item.base, // Adiciona o campo base
                    quote: item.quote, // Adiciona o campo quote
                })); // Extraímos o símbolo, base e quote
                setSymbols(symbolList); // Atualiza os símbolos com base, quote e símbolo
            } catch (error) {
                console.error("Erro ao buscar os símbolos", error);
                setSymbols([]); // Garantir que symbols seja um array vazio em caso de erro
            }
        };
        loadSymbols();
    }, []);

    useEffect(() => {
        const apiUrl = getBinanceAPIUrls(symbol, interval);

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                const updatedData = data.map(([time, open, high, low, close, volume]) => {
                    return {
                        symbol,
                        interval,
                        time: new Date(time).toLocaleString(),
                        open: isNaN(parseFloat(open)) ? 0 : parseFloat(open), // Evita NaN
                        high: isNaN(parseFloat(high)) ? 0 : parseFloat(high), // Evita NaN
                        low: isNaN(parseFloat(low)) ? 0 : parseFloat(low), // Evita NaN
                        close: isNaN(parseFloat(close)) ? 0 : parseFloat(close), // Evita NaN
                        volume: isNaN(parseFloat(volume)) ? 0 : parseFloat(volume), // Evita NaN
                    };
                });

                // Calcular o Supertrend com os dados obtidos
                const highs = updatedData.map(item => item.high);
                const lows = updatedData.map(item => item.low);
                const closes = updatedData.map(item => item.close);
                const supertrend = calculateSupertrend(highs, lows, closes);

                // Anexar Supertrend aos dados
                const dataWithSupertrend = updatedData.map((item, index) => ({
                    ...item,
                    supertrend: supertrend[index] ? supertrend[index].trendDirection : null,
                    upperBand: supertrend[index] ? supertrend[index].upperBand : null,
                    lowerBand: supertrend[index] ? supertrend[index].lowerBand : null,
                }));

                setKlineData(dataWithSupertrend);
                setFilteredData(dataWithSupertrend); // Inicializa os dados filtrados com todos os dados
            })
            .catch((error) => {
                console.error(`Erro ao buscar dados da API:`, error);
            });
    }, [symbol, interval]); // Refaz a busca sempre que o símbolo ou intervalo muda

    // Função para filtrar dados por símbolo e intervalo
    const filterData = () => {
        const filtered = klineData.filter((item) =>
            (symbol ? item.symbol === symbol : true) && (interval ? item.interval === interval : true)
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        filterData(); // Aplica o filtro sempre que os dados ou filtros mudam
    }, [klineData, symbol, interval]);

    // Função para determinar a cor com base no Supertrend
    const getSupertrendColor = (supertrend) => {
        if (supertrend === 1) return "green"; // Uptrend - Verde
        if (supertrend === -1) return "red"; // Downtrend - Vermelho
        return "blue"; // Neutral - Azul
    };

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Binance Analysis</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        {/* Filtros para símbolo e intervalo */}
                        <div>
                            <label>
                                Symbol:
                                <select value={symbol} onChange={(e) => setSymbol(e.target.value)}>
                                    {Array.isArray(symbols) && symbols.length > 0 ? (
                                        symbols.map((s) => (
                                            <option key={s.symbol} value={s.symbol}>
                                                {s.symbol}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            No symbols available
                                        </option>
                                    )}
                                </select>
                            </label>

                            <label>
                                Interval:
                                <select value={interval} onChange={(e) => setInterval(e.target.value)}>
                                    <option value="1h">1 Hour</option>
                                    <option value="4h">4 Hours</option>
                                    <option value="1d">1 Day</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-header">
                            <div className="row">
                                <div className="col">
                                    <h2 className="fs-5 fw-bold mb-0">Kline Data with Supertrend</h2>
                                </div>
                            </div>
                        </div>
                        {/* Tabela para exibir os dados */}
                        <div className="table-responsive">
                            <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="border-bottom" scope="col" >Symbol</th>
                                        <th className="border-bottom col-2" scope="col">Base</th> {/* Coluna para exibir o base */}
                                        <th className="border-bottom col-2" scope="col" >Interval</th>
                                        <th className="border-bottom col-2" scope="col" >Time</th>
                                        <th className="border-bottom col-2" scope="col">Open</th>
                                        <th className="border-bottom col-2" scope="col">High</th>
                                        <th className="border-bottom col-2" scope="col">Low</th>
                                        <th className="border-bottom col-2" scope="col">Close</th>
                                        <th className="border-bottom col-2" scope="col" >Volume</th>
                                        <th className="border-bottom col-2" scope="col">Supertrend</th>
                                        <th className="border-bottom col-2" scope="col">Upper Band</th>
                                        <th className="border-bottom col-2" scope="col">Lower Band</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData && filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td> <img
                                                    className="me-2"
                                                    width={16}
                                                    alt={item.symbol.toLowerCase()}
                                                    src={`/img/icons/color/usdt.svg`}
                                                />{item.symbol}</td>
                                                <td>
                                                    {symbols.find(s => s.symbol === item.symbol)?.base && (
                                                        <>
                                                            <img
                                                                className="me-2"
                                                                width={16}
                                                                alt={item.symbol.toLowerCase()}
                                                                src={`/img/icons/color/${symbols.find(s => s.symbol === item.symbol)?.base.toLowerCase()}.svg`}
                                                            />
                                                            {symbols.find(s => s.symbol === item.symbol)?.base}
                                                        </>
                                                    )}
                                                </td>
                                                <td>{item.interval}</td>
                                                <td>{item.time}</td>
                                                <td>{item.open}</td>
                                                <td>{item.high}</td>
                                                <td>{item.low}</td>
                                                <td>{item.close}</td>
                                                <td>{item.volume}</td>
                                                <td style={{ color: getSupertrendColor(item.supertrend) }}>
                                                    {item.supertrend === 1
                                                        ? <span className="text-success">▲ Uptrend</span>
                                                        : item.supertrend === -1
                                                            ? <span className="text-danger">▼ Downtrend</span>
                                                            : <span className="text-primary">● Neutral</span>}
                                                </td>
                                                <td>{item.upperBand}</td>
                                                <td>{item.lowerBand}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="12" style={{ textAlign: "center" }}>
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>

        </>

    );
}

export default Indexes;
