import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

/**
 * props:
 * - data
 */
function ApexChart({ data }) {
  const DEFAULT_DATA = {
    profit: 0,
    profitPerc: 0,
    sellVolume: 0,
    buyVolume: 0,
    series: [],
    subs: [],
  };

  const [report, setReport] = useState(DEFAULT_DATA);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    // Atualiza o relatório com os dados recebidos
    setReport(data ? data : DEFAULT_DATA);
  }, [data]);

  useEffect(() => {
    if (!report || !report.series || !report.subs) return;

    // Reduz o número de labels para legibilidade (máximo 15 subs)
    const mod = report.subs.length > 20 ? 2 : 1;
    const filteredSubs = report.subs.map((s, i) => (i % mod === 0 ? s : ""));

    // Formatar os dados para limitar as casas decimais
    const formattedSeries = report.series.map((value) => parseFloat(value.toFixed(2))); // Limita para 2 casas decimais

    // Configurações do gráfico
    setChartOptions({
      chart: {
        height: 360,
        type: "area",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.0,
          stops: [0, 100],
          // Gradiente de cor de acordo com o valor
          colorStops: [
            { offset: 0, color: "#1E90FF", opacity: 1 }, // Azul para positivo
            { offset: 100, color: "#FF4500", opacity: 1 }, // Vermelho para negativo
          ],
        },
      },
      xaxis: {
        categories: filteredSubs, // Datas filtradas no eixo X
      },
      tooltip: {
        x: {
          format: "dd/MM",
        },
        y: {
          formatter: (value) => value.toFixed(2), // Formatar tooltip para 2 casas decimais
        },
      },
    });

    // Série de dados do gráfico com cores baseadas na lógica de gradiente
    setChartSeries([
      {
        name: "Lucro/Prejuízo",
        data: formattedSeries, // Use os dados formatados
      },
    ]);
  }, [report]);

  // Formatações auxiliares
  function getTextClass(value) {
    return parseFloat(value) >= 0 ? "text-success" : "text-danger";
  }

  function getText(value) {
    const signal = value > 0 ? "+" : "";
    return signal + (value ? value.toFixed(3) : "0.000"); // Limita para 3 casas decimais
  }

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card bg-yellow-100 border-0 shadow">
          <div className="card-header d-sm-flex flex-row align-items-center flex-0">
            <div className="d-block mb-3 mb-sm-0">
              <h2 className="fs-3 fw-extrabold">
                {report.quote} {getText(report.sellVolume - report.buyVolume)}
              </h2>
            </div>
            <div className="d-block ms-3">
              <div className="small">
                <span className={getTextClass(report.profitPerc) + " fw-bold"}>
                  ({getText(report.profitPerc)}%)
                </span>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApexChart;
