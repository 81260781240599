import axios from './BaseService';

const INDEXES = `${process.env.REACT_APP_API_URL}/s3/`;

export async function getSuperTrend() {
    
    const response = await axios.get(INDEXES + 'supertrend');
    return response.data;
}

export async function getRSI() {
    
    const response = await axios.get(INDEXES + 'rsi');
    return response.data;
}